import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import { firebaseConfig } from "../src/firebaseConfig";
import firebase from "firebase/compat";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Email App
const TheEmailApp = React.lazy(() => import('./views/apps/email/TheEmailApp'));

// Pages
const Landing = React.lazy(() => import('./views/pages/landing/Landing'));
// const Booking = React.lazy(() => import('./views/pages/booking/Booking'));
const Offers = React.lazy(() => import('./views/pages/booking/Offers'));
const AudioStudio = React.lazy(() => import('./views/pages/booking/decors/AudioStudio'));
const VideoStudio = React.lazy(() => import('./views/pages/booking/decors/VideoStudio'));
const DateAndTime = React.lazy(() => import('./views/pages/booking/DateAndTime'));
const Payment = React.lazy(() => import('./views/pages/booking/Payment'));

const VirtualSets = React.lazy(() => import('./views/pages/landing/components/SetSection/VirtualSets'));
const RealSets = React.lazy(() => import('./views/pages/landing/components/SetSection/RealSets'));

const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

class App extends Component {
  
  componentDidMount() {
    this.mainFirebase();
  }

  mainFirebase() {
    // Initialize Firebase
    if (firebase.apps.length <= 0) {
      firebase.initializeApp(firebaseConfig);
    }
  }

  render() {
    return (
      <HashRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
              <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              <Route path="/apps/email" name="Email App" render={props => <TheEmailApp {...props}/>} />
              <Route path="/Offers" name="Offers" render={props => <Offers {...props}/>} />
              <Route path="/virtual-sets" name="VirtualSets" render={props => <VirtualSets {...props}/>} />
              <Route path="/real-sets" name="RealSets" render={props => <RealSets {...props}/>} />
              <Route path="/audio" name="AudioStudio" render={props => <AudioStudio {...props}/>} />
              <Route path="/video/:plan" name="VideoStudio" render={props => <VideoStudio {...props}/>} />
              <Route path="/date-time/audio" name="DateAndTime" render={props => <DateAndTime {...props}/>} />
              <Route path="/date-time/video/:plan" name="DateAndTime" render={props => <DateAndTime {...props}/>} />
              <Route path="/payment" name="Payment" render={props => <Payment {...props}/>} />
              <Route path="/offers" name="Offers" render={props => <Offers {...props}/>} />
              <Route path="/dashboard" name="Home" render={props => <TheLayout {...props}/>} />
              {/* <Route path="/" name="Offers" render={props => <Offers {...props}/>} /> */}
              <Route path="/" name="Landing" render={props => <Landing {...props}/>} />
            </Switch>
          </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
