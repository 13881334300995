// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// Your web app's Firebase configuration
export const firebaseConfig = {
  apiKey: "AIzaSyDSmerOi1F--NZWvxUc1oEY3k23OH1-hwM",
  authDomain: "louna-booking.firebaseapp.com",
  projectId: "louna-booking",
  storageBucket: "louna-booking.appspot.com",
  messagingSenderId: "1019589291352",
  appId: "1:1019589291352:web:3af09d24461a5f360bc92d",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const firestore = getFirestore(app);
